import React, { Component } from 'react';
import { Container, Row, Col, CardDeck } from 'reactstrap';
import SaleProducts from '../SaleProduct/SaleProducts';
import SaleProductProvider from '../../SaleProduct/SaleProductContext';
import PropTypes from 'prop-types';
export default class SaleProductsList extends Component {
    static propTypes = {
        title: PropTypes.bool,
        children: PropTypes.node,
        descriptionFirst: PropTypes.bool,
        buttonText: PropTypes.string,
        showOldPrice: PropTypes.bool
    };

    static defaultProps = {
        title: true,
        children: undefined,
        descriptionFirst: false,
        buttonText: '',
        showOldPrice: false
    };

    render() {
        return (
            <section id="buy">
                <Container>
                    {this.props.title && <h2>Wybierz pakiet dla siebie</h2>}

                    {this.props.children}

                    {this.props.productsGroups.map((group, k) => (
                        <section key={k}>
                            {group.title !== '' ? (
                                <Row>
                                    <Col xs="12" sm="12" md="3">
                                        <h3 className={group.color}>{group.title}</h3>
                                        <p>{group.description}</p>
                                    </Col>

                                    <Col xs="12" sm="12" md="9">
                                        <CardDeck className="multi-products">
                                            <SaleProductProvider
                                                tag={group.tag}
                                                source={this.props.source}
                                                promoCode={this.props.promoCode}
                                            >
                                                <SaleProducts
                                                    color={group.color}
                                                    showThumbnail={
                                                        group.showThumbnail
                                                    }
                                                    showShortDescription={
                                                        group.showShortDescription
                                                    }
                                                    descriptionFirst={
                                                        this.props
                                                            .descriptionFirst
                                                    }
                                                    buttonText={
                                                        this.props.buttonText
                                                    }
                                                    showOldPrice={this.props.showOldPrice}
                                                />
                                            </SaleProductProvider>
                                        </CardDeck>
                                    </Col>
                                </Row>
                            ) : (
                                <CardDeck className="multi-products">
                                    <SaleProductProvider
                                        tag={group.tag}
                                        source={this.props.source}
                                    >
                                        <SaleProducts
                                            color={group.color}
                                            showThumbnail={group.showThumbnail}
                                        />
                                    </SaleProductProvider>
                                </CardDeck>
                            )}
                        </section>
                    ))}
                </Container>
            </section>
        );
    }
}
