import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Card,
    CardBody,
} from 'reactstrap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { scroller } from 'react-scroll';
import Slider from 'react-slick';
import '../helpers/suppress-install-prompt';
import Seo from '../components/seo';
import Layout from '../components/Layout';
import {
    APP_STORE_URL,
    GOOGLE_PLAY_URL,
    APP_GALLERY,
} from '../constants/index';
import SaleProductsList from '../components/SaleProduct/SaleProductsList';
import SaleProductsGroup from '../SaleProduct/SaleProductsGroup';

import { graphql } from 'gatsby';

import arrow from '../images/homepage-new/arrow.svg';
import Waves from '../components/waves';
import logo from '../images/hpba_signet.png';
import appStore from '../images/badge_apple.svg';
import googleStore from '../images/badge_google.svg';
import huaweiStore from '../images/badge_huawej.svg';
import calendar from '../images/trainings/calendar.svg';
import equipment from '../images/trainings/equipment.svg';
import homeWorkout from '../images/trainings/home_workout.svg';
import idea from '../images/trainings/idea.svg';
import intensive from '../images/trainings/intensive.svg';
import levels from '../images/trainings/levels.svg';
import measurements from '../images/trainings/measurements.svg';
import offline from '../images/trainings/offline.svg';
import trainer from '../images/trainings/trainer.svg';
import trainings from '../images/trainings/trainings.svg';
import downArrow from '../images/trainings/chevron-down.svg';

import mealVideo from '../images/black2021/meal_video.mp4';

const Page = ({ data }) => {
    const [currentView, setCurrentView] = useState('profile');
    const [collapse, setCollapse] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
    });

    const handleView = (viewName) => {
        setCurrentView(viewName);
    };

    const renderViewImage = () => {
        return (
            <GatsbyImage
                image={data[currentView].childImageSharp.gatsbyImageData}
                alt=""
            />
        );
    };
    const productsGroups = [
        new SaleProductsGroup(
            'landing:treningi:dietAndWorkout',
            'Dieta + trening',
            'Dostęp do planu diety i treningów.',
            'blue'
        ),
        new SaleProductsGroup(
            'landing:treningi:diet',
            'Dieta',
            'Dostęp tylko do planu diety.',
            'red'
        ),
        new SaleProductsGroup(
            'landing:treningi:workout',
            'Trening',
            'Dostęp tylko do treningów.',
            'black'
        ),
    ];

    const slider = React.createRef();

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    };

    const handleNext = () => {
        slider.current.slickNext();
    };

    const toggle = (id) => {
        setCollapse((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const renderArrow = (id) => {
        if (collapse[id]) {
            return (
                <span className="collapse-arrow open">
                    <span className="SVGInline">
                        <img src={downArrow}></img>
                    </span>
                </span>
            );
        }

        return (
            <span className="collapse-arrow">
                <span className="SVGInline">
                    <img src={downArrow}></img>
                </span>
            </span>
        );
    };
    return (
        <Layout page="trainings" showMenu={true}>
            <Seo
                title="Ćwiczenia i plany treningowe"
                description="Diet & Training by Ann to pierwsza w Polsce aplikacja, której działanie w zakresie zmiany stylu życia jest tak kompleksowe. Odkryj razem ze mną, że zdrowy tryb życia to najlepsza droga do szczęścia - odpowiednia dieta, trening i codzienna dawka motywacji to niezbędne elementy tej ścieżki."
            />
            <section className="hero">
                <Waves />
                <Container>
                    <Row>
                        <Col
                            md="7"
                            className="d-flex flex-column justify-content-center mt-5 mt-md-0"
                        >
                            <h1>
                                <strong>
                                    Proste <br className="d-none d-md-block" />{' '}
                                    i różnorodne treningi
                                </strong>{' '}
                                w 1 aplikacji
                            </h1>
                            <GatsbyImage
                                image={
                                    data.watch.childImageSharp.gatsbyImageData
                                }
                                className="d-md-none mt-4 mt-md-0"
                                style={{
                                    marginLeft: '-15px',
                                    marginRight: '-15px',
                                }}
                                alt=""
                            />

                            <Button
                                color="pink-font"
                                className="mt-4 mx-auto mx-md-0"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Trenuj od zaraz
                            </Button>
                        </Col>
                        <Col md="5" className="d-none d-md-block">
                            <GatsbyImage
                                image={
                                    data.watch.childImageSharp.gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="shop-buttons">
                <Container>
                    <Row>
                        <Col>
                            <div className="logo d-flex justify-content-center">
                                <img src={logo} alt="" />
                            </div>
                            <h4 className="text-center">
                                Wypróbuj w aplikacji <br />{' '}
                                <span className="pink uppercase">diet</span> &{' '}
                                <span className="pink uppercase">
                                    training{' '}
                                </span>
                                <span className="uppercase">by ann</span>
                            </h4>

                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center buttons pt-4">
                                <a
                                    href={GOOGLE_PLAY_URL}
                                    className="mt-3 mt-md-0"
                                >
                                    <img src={googleStore} alt="" />
                                </a>
                                <a
                                    href={APP_STORE_URL}
                                    className="mt-3 mt-md-0"
                                >
                                    <img src={appStore} alt="" />
                                </a>
                                <a href={APP_GALLERY} className="mt-3 mt-md-0">
                                    <img src={huaweiStore} alt="" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="promo">
                <Waves />
                <Container>
                    <Row>
                        <Col
                            md={{ size: 6 }}
                            className="d-flex align-items-center mt-5 mt-md-0 pt-5 pt-md-0"
                        >
                            <div>
                                <h2 className="text-center text-md-left white">
                                    zdrowy styl życia jeszcze nigdy nie był tak
                                    prosty i wygodny
                                </h2>
                                <p className="white text-center text-md-left">
                                    Możesz kupić dziesiątki płyt DVD z
                                    nagraniami… lub pobrać aplikację na iOS lub
                                    Android i już od pierwszego dnia użytkowania
                                    cieszyć się planami treningowymi na ponad
                                    4500 dni – a to wszystko w Twoim telefonie.
                                </p>
                                <p className="white text-center text-md-left">
                                    Żeby ćwiczyć z Diet & Training by Ann nie
                                    potrzebujesz ciągłego dostępu do Internetu –
                                    wystarczy, że raz pobierzesz wybrany trening
                                    na swój telefon i korzystać z niego możesz
                                    już zawsze i wszędzie – w parku, na siłowni,
                                    na wakacjach – offline, czyli bez obciążania
                                    pakietu transferu danych.
                                </p>
                            </div>
                        </Col>
                        <Col md="6">
                            <GatsbyImage
                                image={
                                    data.phone.childImageSharp.gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="animated-slides">
                <Container className="text-center">
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h2 className="mb-md-5">
                                Ponad{' '}
                                <span className="pink">
                                    90 planów treningowych
                                </span>
                            </h2>
                        </Col>
                    </Row>
                </Container>

                <div className="slideshow mt-4">
                    <div className="line1">
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                    <div className="line2">
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                </div>

                <Container className="text-center">
                    <Button
                        color="pink"
                        className="mt-4"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Znajdź swój ulubiony
                    </Button>
                </Container>
            </section>
            <section className="trainers-slider">
                <Waves />
                <Container>
                    <h2 className="text-center">
                        POZNAJ <br className="d-md-none" />
                        SWOICH TRENERÓW
                    </h2>

                    <div className="slider-container">
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.anna.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <h4>Anna Lewandowska</h4>
                                        <p>Treningi funkcjonalne</p>
                                    </div>
                                    <p>
                                        Wielokrotna medalistka mistrzostw
                                        świata, Europy i Polski w karate
                                        tradycyjnym. Była reprezentantka Polski
                                        w karate. Dyplomowana trenerka fitness i
                                        TRX. Z wykształcenia manager sportowy i
                                        dietetyk.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.jj.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <h4>Joanna Jędrzejczyk</h4>
                                        <p>Treningi z elementami sztuk walki</p>
                                    </div>
                                    <p>
                                        Reprezentantka Polski w boksie,
                                        kick-boxingu, muay thai oraz mieszanych
                                        sztukach walki (MMA). Trzykrotna
                                        zawodowa mistrzyni świata. Pierwsza
                                        Polka w prestiżowej organizacji Ultimate
                                        Fighting Championship.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.mariola.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <h4>Mariola Bojarska - Ferenc</h4>
                                        <p>Treningi dla dojrzałych</p>
                                    </div>
                                    <p>
                                        Absolwentka Akademii Wychowania
                                        Fizycznego w Warszawie. Była członkini
                                        kadry narodowej w gimnastyce
                                        artystycznej. Instruktorka fitness z
                                        wieloletnim doświadczeniem, członkini
                                        najważniejszych światowych organizacji
                                        fitness. Swoje treningi kieruje przede
                                        wszystkim do osób dojrzałych.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.ewelina.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <h4>Ewelina Godlewska</h4>
                                        <p>Yoga</p>
                                    </div>
                                    <p>
                                        Dyplomowana nauczycielka yogi z ponad
                                        10-letnim doświadczeniem. Z
                                        wykształcenia psycholog i instruktor
                                        rekreacji ruchowej o specjalności
                                        ćwiczenia psychofizyczne w oparciu o
                                        system hatha yogi.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.kuba.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <h4>Jakub Głąb</h4>
                                        <p>Treningi dla mężczyzn i par</p>
                                    </div>
                                    <p>
                                        Mistrz Polski w Fitness Sylwetkowym,
                                        wielokrotny Mistrz Polski, Europy i
                                        Świata w Karate Tradycyjnym. Z zawodu
                                        trener personalny dietetyk, instruktor
                                        samoobrony i fitness, certyfikowany
                                        trener TRX.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                        <button
                            className="slider-btn prev"
                            onClick={handlePrev}
                        >
                            <img
                                src={arrow}
                                className="rotate180deg"
                                alt="wstecz"
                            />
                        </button>
                        <button
                            className="slider-btn next"
                            onClick={handleNext}
                        >
                            <img src={arrow} alt="dalej" />
                        </button>
                    </div>
                </Container>
            </section>
            <section className="tiles">
                <Container className="text-center">
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h2 className="mb-md-5">
                                Treningi{' '}
                                <span className="pink">dla wszystkich</span>
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm="12" md="4" className="mt-3 mt-md-0">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.woman.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info">
                                    <p>kobiet</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.man.childImageSharp.gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info">
                                    <p>mężczyzn</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.yoga.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info">
                                    <p>adeptów Yogi</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-md-5">
                        <Col sm="12" md="4" className="mt-4 mt-md-0">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.pregnant.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info w-50">
                                    <p>młodych i ciężarnych mam</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4" className="mt-4 mt-md-0">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.fighter.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info w-75">
                                    <p>
                                        ćwiczących lub chcących ćwiczyć sztuki
                                        walki
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4" className="mt-4 mt-md-0">
                            <div className="tile">
                                <GatsbyImage
                                    image={
                                        data.senior.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <div className="info w-85">
                                    <p>
                                        osób dojrzałych (jedyna aplikacja na
                                        świecie z treningami 50+)
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fitness-exercise pt-0">
                <Waves color="pink" />
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2>
                                <span className="pink">proste</span> ćwiczenia
                                fitness na:
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mt-5 d-none d-md-flex">
                        <Col sm={{ size: '4' }}>
                            <ul className="ullist h-100">
                                <li>
                                    <h4>mocne plecy</h4>
                                </li>
                                <li className="translate-minus">
                                    <h4>wzmacnianie i kondycję</h4>
                                </li>
                                <li>
                                    <h4>jędrne uda i pośladki</h4>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={{ size: '4' }}>
                            <GatsbyImage
                                image={
                                    data.exercise.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col sm={{ size: '4' }}>
                            <ul className="ullist h-100">
                                <li>
                                    <h4>płaski brzuch</h4>
                                </li>
                                <li className="translate-plus">
                                    <h4>smukłe ramiona i nogi</h4>
                                </li>
                                <li>
                                    <h4>spalanie tkanki tłuszczowej</h4>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="d-md-none align-items-center">
                        <Col>
                            <ul className="ullist h-100">
                                <li>
                                    <h4>mocne plecy</h4>
                                </li>
                                <li className="translate-minus">
                                    <h4>wzmacnianie i kondycję</h4>
                                </li>
                                <li>
                                    <h4>jędrne uda i pośladki</h4>
                                </li>
                                <li>
                                    <h4>płaski brzuch</h4>
                                </li>
                                <li className="translate-plus">
                                    <h4>smukłe ramiona i nogi</h4>
                                </li>
                                <li>
                                    <h4>spalanie tkanki tłuszczowej</h4>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <GatsbyImage
                                image={
                                    data.exercise.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <h3 className="text-center text-uppercase mt-5">
                    Tak jak chcesz - programy ze sprzętem lub bez!
                </h3>
            </section>
            <section className="goals pt-0">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <h2>
                                <span className="pink">osiągaj</span> wymarzone
                                cele
                            </h2>
                        </Col>
                    </Row>
                    <Row className="text-center justify-content-between mt-5">
                        <Col xs="6" md="2">
                            <img src={measurements} alt="" />
                            <p className="big">Pomiary i śledzenie postępów</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={equipment} alt="" />
                            <p className="big">Treningi ze sprzętem lub bez</p>
                        </Col>
                        <Col xs="6" md="2" className="mt-5 mt-md-0">
                            <img src={trainer} alt="" />
                            <p className="big">Opieka trenerów bez limitu</p>
                        </Col>
                        <Col xs="6" md="2" className="mt-5 mt-md-0">
                            <img src={idea} alt="" />
                            <p className="big">Rady, wskazówki, motywacja</p>
                        </Col>
                        <Col xs="6" md="2" className="mt-5 mt-md-0">
                            <img src={calendar} alt="" />
                            <p className="big">Kalendarz treningów</p>
                        </Col>
                        <Col xs="6" className="d-md-none mt-5 mt-md-0">
                            <img src={homeWorkout} alt="" />
                            <p className="big">
                                Dostosowane do wykonania w domu
                            </p>
                        </Col>
                        <Col xs="6" className="d-md-none mt-5">
                            <img src={offline} alt="" />
                            <p className="big">Treningi dostępne offline</p>
                        </Col>
                        <Col xs="6" className="d-md-none mt-5">
                            <img src={levels} alt="" />
                            <p className="big">Różne poziomy trudności</p>
                        </Col>
                        <Col xs="6" className="d-md-none mt-5">
                            <img src={trainings} alt="" />
                            <p className="big">
                                Dostęp do wszystkich treningów
                            </p>
                        </Col>
                        <Col xs="6" className="d-md-none mt-5">
                            <img src={intensive} alt="" />
                            <p className="big">Wybór intensywności</p>
                        </Col>
                    </Row>
                    <Row className="text-center justify-content-between mt-md-3 d-none d-md-flex">
                        <Col xs="6" md="2">
                            <img src={homeWorkout} alt="" />
                            <p className="big">
                                Dostosowane do wykonania w domu
                            </p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={offline} alt="" />
                            <p className="big">Treningi dostępne offline</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={levels} alt="" />
                            <p className="big">Różne poziomy trudności</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={trainings} alt="" />
                            <p className="big">
                                Dostęp do wszystkich treningów
                            </p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={intensive} alt="" />
                            <p className="big">Wybór intensywności</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Button
                                color="pink"
                                className="mt-5 w-75 px-4"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Chcę spróbować
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="profil">
                <Waves />
                <Container>
                    <h2 className="text-center white mt-5 pt-5 mt-md-0 pt-md-0">
                        wypróbuj koniecznie
                    </h2>

                    <Row className="mt-5">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 5, order: 1 }}
                            className="mt-4 mt-md-0 pb-5 pb-md-0"
                        >
                            {renderViewImage()}
                        </Col>
                        <Col
                            className="my-auto"
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 7, order: 2 }}
                        >
                            <ul className="text-center text-md-right pl-0 pl-md-5">
                                <li
                                    className={
                                        currentView === 'balance' && 'active'
                                    }
                                    onClick={() => handleView('balance')}
                                >
                                    <hr
                                        className={
                                            currentView === 'balance'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />

                                    <h4>Balans</h4>
                                </li>
                                <li
                                    className={
                                        currentView === 'sleep'
                                            ? 'active mt-5'
                                            : 'mt-5'
                                    }
                                    onClick={() => handleView('sleep')}
                                >
                                    <hr
                                        className={
                                            currentView === 'sleep'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />

                                    <h4>Sen</h4>
                                </li>
                                <li
                                    className={
                                        currentView === 'hydration'
                                            ? 'active mt-5'
                                            : 'mt-5'
                                    }
                                    onClick={() => handleView('hydration')}
                                >
                                    <hr
                                        className={
                                            currentView === 'hydration'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />

                                    <h4>Nawodnienie</h4>
                                </li>
                                <li
                                    className={
                                        currentView === 'profile'
                                            ? 'active mt-5'
                                            : 'mt-5'
                                    }
                                    onClick={() => handleView('profile')}
                                >
                                    <hr
                                        className={
                                            currentView === 'profile'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />
                                    <h4>Profil</h4>
                                </li>
                                <li
                                    className={
                                        currentView === 'breathing'
                                            ? 'active mt-5'
                                            : 'mt-5'
                                    }
                                    onClick={() => handleView('breathing')}
                                >
                                    <hr
                                        className={
                                            currentView === 'breathing'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />
                                    <h4>Ćwiczenia oddechowe</h4>
                                </li>
                                <li
                                    className={
                                        currentView === 'spotify'
                                            ? 'active mt-5'
                                            : 'mt-5'
                                    }
                                    onClick={() => handleView('spotify')}
                                >
                                    <hr
                                        className={
                                            currentView === 'spotify'
                                                ? 'd-none d-md-block'
                                                : 'd-none'
                                        }
                                    />
                                    <div>
                                        <h4>Apple Music lub Spotify</h4>
                                        <p>
                                            by korzystać z motywacyjnych
                                            playlist <br /> w trakcie treningu
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="apple-watch">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col
                            xs="12"
                            md={{ size: 6, order: 1 }}
                            className="text-center text-md-left"
                        >
                            <h2>
                                <span className="pink py-3">Nowość!</span>
                                <br />
                                trenuj z{' '}
                                <span className="pink">apple watch</span>
                            </h2>
                            <p>
                                Dzięki wersji Diet&Training by Ann dostępnej na
                                zegarki Apple Watch sprawdzaj na bieżąco
                                informacje podczas treningu - aktualny puls i
                                liczbę spalonych kalorii - wszystko na Twoim
                                nadgarstku.
                            </p>
                            <p>
                                Śledzenie treningów jest dzięki temu jeszcze
                                prostsze!
                            </p>
                        </Col>
                        <Col
                            xs="12"
                            md={{ size: 5, order: 2 }}
                            className="mt-4 mt-md-0"
                        >
                            <GatsbyImage
                                image={
                                    data.appleWatchTraining.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="diet-type pt-0">
                <Container fluid>
                    <Row>
                        <Col
                            xs={{ order: 2 }}
                            md={{ size: 4, offset: 1, order: 2 }}
                            className="pt-5 pt-md-0"
                        >
                            <h2 className="mt-5 pt-5 mt-md-0 pt-md-0">
                                Jesz tak,{' '}
                                <span className="pink">jak lubisz</span>
                            </h2>
                            <p>
                                Każdy z nas jest inny. Mamy odmienne upodobania
                                żywieniowe, przyzwy­czajenia i każdy z nas
                                toleruje inne grupy produ­któw. Dlatego w
                                aplikacji znajdziesz propozycje kilku wariantów
                                diety i ułatwiające gotowanie filmiki z
                                przepisami!
                            </p>
                            <div className="collapse-section">
                                <div className="collapse-item">
                                    <h3>
                                        <button
                                            type="button"
                                            onClick={() => toggle(1)}
                                        >
                                            Classic 5 posiłków
                                            {renderArrow(1)}
                                        </button>
                                    </h3>
                                    <Collapse isOpen={collapse[1]}>
                                        <Card>
                                            <CardBody>
                                                <p>
                                                    Wyklucza pszenicę i jej
                                                    pochodne oraz mleko krowie.
                                                    Źródłem białka w diecie są
                                                    głównie: mięso, ryby, nabiał
                                                    i jaja.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                                <div className="collapse-item">
                                    <h3>
                                        <button
                                            type="button"
                                            onClick={() => toggle(2)}
                                        >
                                            Low IG
                                            {renderArrow(2)}
                                        </button>
                                    </h3>
                                    <Collapse isOpen={collapse[2]}>
                                        <Card>
                                            <CardBody>
                                                <p>
                                                    Dieta bazuje na produktach
                                                    posiadających niski indeks
                                                    glikemiczny (IG). Wyklucza
                                                    pszenicę i jej pochodne oraz
                                                    mleko krowie. Idealna opcja,
                                                    jeśli chcesz ograniczyć
                                                    spożycie cukrów prostych i
                                                    zadbać o uregulowanie
                                                    poziomu cukru we krwi.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                                <div className="collapse-item">
                                    <h3>
                                        <button
                                            type="button"
                                            onClick={() => toggle(3)}
                                        >
                                            Smart 3 posiłki
                                            {renderArrow(3)}
                                        </button>
                                    </h3>
                                    <Collapse isOpen={collapse[3]}>
                                        <Card>
                                            <CardBody>
                                                <p>
                                                    Najszybszy i najłatwiejszy
                                                    wariant diety. Wspiera
                                                    działanie Trójkąta Mocy,
                                                    wykluczając przy tym
                                                    pszenicę i jej pochodne oraz
                                                    mleko krowie. Idealny
                                                    wariant dla osób chcących
                                                    spędzać w kuchni minimum
                                                    czasu.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                                <div className="collapse-item">
                                    <h3>
                                        <button
                                            type="button"
                                            onClick={() => toggle(4)}
                                        >
                                            Vege 5 posiłków
                                            {renderArrow(4)}
                                        </button>
                                    </h3>
                                    <Collapse isOpen={collapse[4]}>
                                        <Card>
                                            <CardBody>
                                                <p>
                                                    Wyklucza mięso, ryby, owoce
                                                    morza i mleko krowie.
                                                    Uwzględnia natomiast jaja i
                                                    nasiona roślin strączkowych.
                                                    Węglowodany złożone
                                                    dostarcza w postaci chleba
                                                    żytniego, kasz i produktów
                                                    bezglutenowych. Odpowiednią
                                                    podaż wapnia zapewnia dzięki
                                                    serom i mlecznym napojom
                                                    fermentowanym (takim jak:
                                                    kefiry, jogurty, czy
                                                    maślanki). Uwzględnia
                                                    również napoje roślinne oraz
                                                    nabiał kozi.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                            <Button
                                color="pink"
                                className="mt-3 w-75 px-4"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Próbuję treningów i diety
                            </Button>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 5, order: 1 }}
                            className="first-col pl-0 pr-md-0"
                        >
                            <div className="img-right">
                                <Waves />

                                <div className="wrapper">
                                    <GatsbyImage
                                        image={
                                            data.mealDetailsPhone
                                                .childImageSharp.gatsbyImageData
                                        }
                                        alt=""
                                    />
                                    <video
                                        className="meal-video"
                                        aria-hidden="true"
                                        muted
                                        autoPlay
                                        playsInline
                                        loop
                                    >
                                        <source
                                            src={mealVideo}
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="promo pb-0">
                <Waves />
                <Container>
                    <Row>
                        <Col
                            md={{ size: 7 }}
                            className="d-flex align-items-center"
                        >
                            <div>
                                <h2 className="mt-5 mt-md-0 text-center text-md-left white">
                                    podejmij wyzwanie
                                </h2>
                                <p className="white mt-5 text-center text-md-left">
                                    <strong>Diet & Training by Ann </strong>to
                                    wszystkie elementy składowe Twojej drogi do
                                    szczęścia, zebrane w jednym miejscu i
                                    dostępne dla Ciebie zawsze i wszędzie – bo w
                                    Twoim telefonie!
                                </p>
                                <Row className="mt-5 justify-content-center">
                                    <Col
                                        xs="12"
                                        md="4"
                                        className="d-flex justify-content-center mt-3 mt-md-0"
                                    >
                                        <a href={GOOGLE_PLAY_URL}>
                                            <img src={googleStore} alt="" />
                                        </a>
                                    </Col>
                                    <Col
                                        xs="12"
                                        md="4"
                                        className="d-flex justify-content-center mt-3 mt-md-0"
                                    >
                                        <a href={APP_STORE_URL}>
                                            <img src={appStore} alt="" />
                                        </a>
                                    </Col>
                                    <Col
                                        xs="12"
                                        md="4"
                                        className="d-flex justify-content-center mt-3 mt-md-0"
                                    >
                                        <a href={APP_GALLERY}>
                                            <img src={huaweiStore} alt="" />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="5">
                            <GatsbyImage
                                image={
                                    data.aniaWithPhone.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <SaleProductsList
                title={false}
                productsGroups={productsGroups}
                source="mariola"
            >
                <h2 className="text-center">
                    Wybierz pakiet i <span className="pink">zacznij dziś!</span>
                </h2>
            </SaleProductsList>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query {
        watch: file(relativePath: { eq: "trainings/watch_img.png" }) {
            ...fluidImage1920
        }
        phone: file(relativePath: { eq: "trainings/training_program.png" }) {
            ...fluidImage1920
        }

        programsA: file(relativePath: { eq: "homepage-new/programs-a.png" }) {
            ...fluidImage1920
        }
        programsB: file(relativePath: { eq: "homepage-new/programs-b.png" }) {
            ...fluidImage1920
        }
        anna: file(relativePath: { eq: "homepage-new/anna-test.png" }) {
            ...fluidImage1920
        }
        ewelina: file(relativePath: { eq: "homepage-new/ewelina-test.png" }) {
            ...fluidImage1920
        }
        mariola: file(relativePath: { eq: "homepage-new/mariola-test.png" }) {
            ...fluidImage1920
        }
        kuba: file(relativePath: { eq: "homepage-new/kuba-test.png" }) {
            ...fluidImage1920
        }
        jj: file(relativePath: { eq: "homepage-new/jj-test.png" }) {
            ...fluidImage1920
        }
        fighter: file(relativePath: { eq: "trainings/program_fighter.png" }) {
            ...fluidImage1920
        }
        man: file(relativePath: { eq: "trainings/program_man.png" }) {
            ...fluidImage1920
        }
        pregnant: file(relativePath: { eq: "trainings/program_pregnant.png" }) {
            ...fluidImage1920
        }
        senior: file(relativePath: { eq: "trainings/program_senior.png" }) {
            ...fluidImage1920
        }
        woman: file(relativePath: { eq: "trainings/program_woman.png" }) {
            ...fluidImage1920
        }
        yoga: file(relativePath: { eq: "trainings/program_yoga.png" }) {
            ...fluidImage1920
        }

        exercise: file(relativePath: { eq: "trainings/exercise_screen.png" }) {
            ...fluidImage1920
        }

        sleep: file(relativePath: { eq: "trainings/sleep_screen.png" }) {
            ...fluidImage1920
        }

        profile: file(relativePath: { eq: "trainings/profile.png" }) {
            ...fluidImage1920
        }

        hydration: file(relativePath: { eq: "trainings/hydration.png" }) {
            ...fluidImage960
        }

        balance: file(relativePath: { eq: "trainings/balance.png" }) {
            ...fluidImage960
        }

        breathing: file(relativePath: { eq: "trainings/breathing.png" }) {
            ...fluidImage960
        }

        spotify: file(relativePath: { eq: "trainings/spotify.png" }) {
            ...fluidImage960
        }

        appleWatchTraining: file(
            relativePath: { eq: "trainings/apple_watch_training.jpg" }
        ) {
            ...fluidImage1920
        }
        phone2: file(relativePath: { eq: "homepage-new/phone2.png" }) {
            ...fluidImage960
        }

        aniaWithPhone: file(
            relativePath: { eq: "trainings/Ania_with_phone.png" }
        ) {
            ...fluidImage960
        }
        mealDetailsPhone: file(
            relativePath: { eq: "black2021/meal_details_phone.png" }
        ) {
            ...fluidImage1920
        }
    }
`;
